import { Internal } from '../internal';
import { NearProvider } from './NearProvider';

export class Near {
  internal: Internal;

  constructor(internal: Internal) {
    this.internal = internal;
  }

  getProvider(): NearProvider {
    return new NearProvider(this.internal);
  }
}
