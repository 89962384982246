import { Internal } from '../internal';
import { AptosProvider } from './AptosProvider';

export class Aptos {
  internal: Internal;

  constructor(internal: Internal) {
    this.internal = internal;
  }

  getProvider(): AptosProvider {
    return new AptosProvider(this.internal);
  }
}
