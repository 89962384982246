import { Blockchain, Env, Network } from '@haechi-labs/face-types';

export function isMainnet(network: Network) {
  switch (network) {
    case Network.ETHEREUM:
    case Network.POLYGON:
    case Network.BNB_SMART_CHAIN:
    case Network.KLAYTN:
    case Network.SOLANA:
    case Network.BORA:
    case Network.NEAR:
    case Network.APTOS:
    case Network.MEVERSE:
    case Network.PSM:
      return true;
    default:
      return false;
  }
}

export function getBlockchainFromNetwork(network?: Network): Blockchain {
  switch (network) {
    case Network.ETHEREUM:
    case Network.GOERLI:
      return Blockchain.ETHEREUM;
    case Network.POLYGON:
    case Network.MUMBAI:
      return Blockchain.POLYGON;
    case Network.BNB_SMART_CHAIN:
    case Network.BNB_SMART_CHAIN_TESTNET:
      return Blockchain.BNB_SMART_CHAIN;
    case Network.KLAYTN:
    case Network.BAOBAB:
      return Blockchain.KLAYTN;
    case Network.SOLANA:
    case Network.SOLANA_DEVNET:
      return Blockchain.SOLANA;
    case Network.BORA:
    case Network.BORA_TESTNET:
      return Blockchain.BORA;
    case Network.NEAR:
    case Network.NEAR_TESTNET:
      return Blockchain.NEAR;
    case Network.APTOS:
    case Network.APTOS_TESTNET:
      return Blockchain.APTOS;
    case Network.MEVERSE:
    case Network.MEVERSE_TESTNET:
      return Blockchain.MEVERSE;
    case Network.PSM:
    case Network.PSM_TESTNET:
      return Blockchain.PSM;
    default:
      return Blockchain.ETHEREUM;
  }
}

export function getIframeUrl(env?: Env, iframeUrl?: string): string {
  if (iframeUrl != null) {
    return iframeUrl;
  }
  switch (env) {
    case Env.Local:
      return 'http://localhost:3333';
    case Env.Dev:
      return 'https://app.dev.facewallet.xyz';
    case Env.StageTest:
      return 'https://app.stage-test.facewallet.xyz';
    case Env.ProdTest:
      return 'https://app.test.facewallet.xyz';
    case Env.StageMainnet:
      return 'https://app.stage.facewallet.xyz';
    case Env.ProdMainnet:
      return 'https://app.facewallet.xyz';
    default:
      return 'https://app.facewallet.xyz';
  }
}

export function getDefaultEnv(network?: Network): Env {
  if (!network) {
    return Env.ProdMainnet;
  }
  if (
    network === Network.GOERLI ||
    network === Network.MUMBAI ||
    network === Network.BAOBAB ||
    network === Network.BNB_SMART_CHAIN_TESTNET ||
    network === Network.BORA_TESTNET ||
    network === Network.SOLANA_DEVNET ||
    network === Network.NEAR_TESTNET ||
    network === Network.APTOS_TESTNET ||
    network === Network.MEVERSE_TESTNET ||
    network === Network.PSM_TESTNET
  ) {
    return Env.ProdTest;
  }
  return Env.ProdMainnet;
}
