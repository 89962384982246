import {
  Blockchain,
  Env,
  JsonRpcMethod,
  JsonRpcRequestPayload,
  JsonRpcSource,
  unsupportedChainError,
} from '@haechi-labs/face-types';

const overlayStyles: Partial<CSSStyleDeclaration> = {
  display: 'none',
  position: 'fixed',
  top: '0',
  right: '0',
  width: '100%',
  height: '100%',
  borderRadius: '0',
  border: 'none',
  zIndex: '2147483647',
};

const SDK_TYPE = 'web';

function applyOverlayStyles(elem: HTMLElement) {
  for (const [cssProperty, value] of Object.entries(overlayStyles)) {
    /* eslint-disable-next-line no-param-reassign */
    (elem.style as any)[cssProperty as any] = value;
  }
}

class Ready {
  private _isCompleted = false;
  // Wait for resolve, resolve if iframe becomes ready state.
  private _eventListeners = Array<() => void>();
  complete = () => {
    this._isCompleted = true;
    this._eventListeners.forEach((eventListener) => eventListener());
  };

  add = (eventListener: () => void) => {
    this._eventListeners.push(eventListener);
  };

  isCompleted = () => {
    return this._isCompleted;
  };
}

export class Iframe {
  private readonly _iframe!: Promise<HTMLIFrameElement>;
  private readonly _env: Env;
  private readonly _ready = new Ready();
  private _blockchain: Blockchain;
  private randomPrefix: string;
  private requestIndex = 0;
  private activeElement: any = null;

  public iframeUrl: string;

  constructor(apiKey: string, blockchain: Blockchain, env: Env, iframeUrl: string) {
    this._blockchain = blockchain;
    this._env = env;
    this.iframeUrl = iframeUrl;

    const randomNumber = Math.random().toString();
    this.randomPrefix = randomNumber.substring(randomNumber.length - 6);

    if (document.getElementById('face-iframe')) {
      throw new Error('Face is already initialized, Face can be initialized once.');
    }

    window.addEventListener('message', async (e) => {
      if (e.origin !== iframeUrl) {
        return;
      }
      await this.processMessage(e.data);
    });

    const version = process.env.npm_package_version ?? '';

    this._iframe = new Promise((resolve) => {
      const onload = () => {
        if (!document.getElementById('face-iframe')) {
          const iframe = document.createElement('iframe');
          iframe.id = 'face-iframe';
          iframe.title = 'Secure Modal';
          iframe.src = new URL(
            // TODO: apiKey는 encodeURIComponent로 감싸줘야 한다 (사이드 이펙트가 있을까 다음에 적용)
            `${iframeUrl}?api_key=${apiKey}&blockchain=${blockchain}&env=${env}&version=${version}&type=${SDK_TYPE}`
          ).href;
          iframe.allow = 'clipboard-read; clipboard-write';
          applyOverlayStyles(iframe);
          document.body.appendChild(iframe);
          resolve(iframe);
        } else {
          // TODO: iframe already initialized
        }
      };

      // Check DOM state and load...
      if (['loaded', 'interactive', 'complete'].includes(document.readyState)) {
        onload();
      } else {
        // ...or check load events to load
        window.addEventListener('load', onload, false);
      }
    });
  }

  async sendChildMessage(data: any): Promise<string> {
    await this.ready();
    this.requestIndex += 1;
    const requestId = data.id ? `${data.id}` : `${this.randomPrefix}-${this.requestIndex}`;
    data.id = requestId;
    data.from = JsonRpcSource.FACE_SDK;
    (await this._iframe)?.contentWindow?.postMessage(data, '*');
    return requestId;
  }

  waitForResponse<T>(requestId?: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const listener = (event: MessageEvent) => {
        const response = event.data;
        if (requestId && response.id !== requestId) {
          return;
        }
        window.removeEventListener('message', listener);

        if (response.error) {
          reject(response.error);
          return;
        }
        resolve(response.result as T);
      };
      window.addEventListener('message', listener);
    });
  }

  async processMessage(message: JsonRpcRequestPayload): Promise<void> {
    switch (message.method) {
      case JsonRpcMethod.face_ready:
        this._ready.complete();
        break;
      case JsonRpcMethod.face_openIframe:
        await this.showOverlay();
        break;
      case JsonRpcMethod.face_closeIframe:
        await this.hideOverlay();
        break;
    }
  }

  async ready(): Promise<void> {
    return new Promise(async (resolve) => {
      if (this._ready.isCompleted()) {
        resolve();
        return;
      }

      this._ready.add(() => {
        resolve();
      });
    });
  }

  async showOverlay() {
    await this.ready();
    const iframe = await this._iframe;
    iframe.style.display = 'block';
    this.activeElement = document.activeElement;
    iframe.focus();
  }

  async hideOverlay() {
    await this.ready();
    const iframe = await this._iframe;
    iframe.style.display = 'none';
    if (this.activeElement?.focus) this.activeElement.focus();
    this.activeElement = null;
  }

  setBlockchain(blockchain: Blockchain) {
    this._blockchain = blockchain;
  }

  throwExceptionUnsupportedBlockchain(blackList: Blockchain[]) {
    if (blackList.includes(this._blockchain)) {
      throw unsupportedChainError();
    }
  }

  getBlockchain() {
    return this._blockchain;
  }
}
