import {
  AptosSignMessagePayload,
  AptosSignMessageResponse,
  AptosTxOptions,
  Blockchain,
  JsonRpcMethod,
} from '@haechi-labs/face-types';
import { sha3_256 as sha3Hash } from '@noble/hashes/sha3';
import { BCS, HexString, TxnBuilderTypes, Types } from 'aptos';

import { Internal } from '../internal';

export class AptosProvider {
  internal: Internal;

  constructor(internal: Internal) {
    this.internal = internal;
  }

  async getPublicKeys(): Promise<HexString[]> {
    const addresses = await this.internal.getAddresses(Blockchain.APTOS);
    return addresses.map((address) => {
      return HexString.ensure(address);
    });
  }

  async getAddresses(): Promise<HexString[]> {
    const addresses = await this.internal.getAddresses(Blockchain.APTOS);
    return addresses.map((add) => {
      const publicKey = Uint8Array.from(
        add
          .substring(2)
          .match(/.{1,2}/g)!
          .map((byte) => parseInt(byte, 16))
      );

      const bytes = new Uint8Array(publicKey.length + 1);
      bytes.set(publicKey);
      bytes.set([0], publicKey.length);

      const hash = sha3Hash.create();
      hash.update(bytes);
      return HexString.fromUint8Array(hash.digest());
    });
  }

  async signMultiAgentTransaction(rawTransaction: TxnBuilderTypes.MultiAgentRawTransaction) {
    // TODO: rawTransaction 유효성 검사
    const result = await this.internal.sendRpc<string>({
      method: JsonRpcMethod.aptos_signMultiAgentTransaction,
      params: [rawTransaction.raw_txn.sender.address, BCS.bcsToBytes(rawTransaction)],
    });

    return result!;
  }

  async signMessage(message: AptosSignMessagePayload): Promise<AptosSignMessageResponse> {
    const serializeMessage = Buffer.from(JSON.stringify(message));
    const serializedRes = await this.internal.sendRpc<string>({
      method: JsonRpcMethod.aptos_signMessage,
      params: [serializeMessage],
    });

    return JSON.parse(serializedRes!);
  }

  async signAndSubmitTransaction(
    transaction: Types.TransactionPayload_EntryFunctionPayload,
    options?: AptosTxOptions
  ): Promise<{
    hash: Types.HexEncodedBytes;
  }> {
    const senderAddress = (await this.internal.getAddresses(Blockchain.APTOS))[0];
    const result = await this.internal.sendRpc<string>({
      method: JsonRpcMethod.aptos_signAndSubmitTransaction,
      params: [Buffer.from(senderAddress), Buffer.from(JSON.stringify(transaction)), options],
    });

    return {
      hash: result!,
    };
  }

  async signTransaction(
    transaction: Types.TransactionPayload_EntryFunctionPayload,
    options?: AptosTxOptions
  ): Promise<Types.SubmitTransactionRequest> {
    const senderAddress = (await this.internal.getAddresses(Blockchain.APTOS))[0];
    const result = await this.internal.sendRpc<string>({
      method: JsonRpcMethod.aptos_signTransaction,
      params: [Buffer.from(senderAddress), Buffer.from(JSON.stringify(transaction)), options],
    });

    return JSON.parse(result!);
  }
}
