import { assertArgument, HomeOptions } from '@haechi-labs/face-types';

import { Internal } from './internal';

/**
 * The interface to control view of face wallet.
 */
export class Wallet {
  private readonly internal: Internal;

  constructor(internal: Internal) {
    this.internal = internal;
  }

  close() {
    this.internal.iframe.hideOverlay();
  }

  async home(options?: HomeOptions) {
    assertArgument(options?.networks, options?.networks?.length, 'options.networks', false);

    return this.internal.openHome(options);
  }
}
