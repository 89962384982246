import {
  assert,
  assertArgument,
  Blockchain,
  Env,
  isEthlikeBlockchain,
  isSupportedNetwork,
  Network,
  NetworkChainIdMap,
  networkToBlockchain,
  unsupportedChainError,
} from '@haechi-labs/face-types';

import { Auth } from './auth';
import { Aptos } from './blockchain/Aptos';
import { Bora } from './blockchain/Bora';
import { Near } from './blockchain/Near';
import { Solana } from './blockchain/Solana';
import { Internal } from './internal';
import { Provider } from './provider';
import { Wallet } from './wallet';
import { WalletConnect } from './walletConnect';

export { Network };

export interface FaceConfig {
  apiKey: string;
  network?: Network | number;
}

export class Face {
  public readonly internal: Internal;
  public network: Network;
  public wc: WalletConnect;
  public auth: Auth;
  public solana: Solana;
  public near: Near;
  public wallet: Wallet;
  public bora: Bora;
  public aptos: Aptos;

  constructor({ apiKey, network, ...rest }: FaceConfig) {
    assertArgument(apiKey, typeof apiKey === 'string', 'apiKey');
    assertArgument(network, true, 'network');

    const _network: Network =
      typeof network === 'number' ? (NetworkChainIdMap[network] as Network) : network!;
    assert(isSupportedNetwork(_network), unsupportedChainError);

    this.network = _network!;
    this.internal = new Internal({
      apiKey,
      network: _network,
      env: (rest as { env?: Env })?.env,
      iframeUrl: (rest as { iframeUrl?: string })?.iframeUrl,
      face: this,
    });
    this.auth = new Auth(this.internal);
    this.wc = new WalletConnect(this.internal);
    this.solana = new Solana(this.internal);
    this.near = new Near(this.internal);
    this.wallet = new Wallet(this.internal);
    this.bora = new Bora(this.internal);
    this.aptos = new Aptos(this.internal);
  }

  async ready(): Promise<void> {
    return this.internal.ready();
  }

  getEthLikeProvider(): Provider {
    assert(isEthlikeBlockchain(networkToBlockchain(this.network)), unsupportedChainError);

    return new Provider(this.internal);
  }

  getAddresses = async (blockchain?: Blockchain): Promise<string[]> => {
    assertArgument(blockchain, blockchain && Blockchain[blockchain], 'blockchain', false);

    return await this.internal.getAddresses(blockchain);
  };

  setNetwork = (network: Network) => {
    assertArgument(network, isSupportedNetwork(network), 'network');

    this.network = network;
  };

  getNetwork = (): Network => {
    return this.network;
  };

  getChainId = async (): Promise<number> => {
    return Number(await this.internal.sendRpc({ method: 'eth_chainId', params: [] }));
  };

  async switchNetwork(network: Network | number) {
    const _network: Network =
      typeof network === 'number' ? (NetworkChainIdMap[network] as Network) : network!;
    assertArgument(network, isSupportedNetwork(_network), 'network');

    return this.internal.switchNetwork(_network);
  }
}
