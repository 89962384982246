import {
  assertArgument,
  FaceIdTokenResponse,
  FaceLoginResponse,
  LoginProvider,
  LoginProviderType,
  LoginWithAccessTokenRequest,
  LoginWithIdTokenRequest,
} from '@haechi-labs/face-types';

import { Internal } from './internal';

export class Auth {
  private readonly internal: Internal;

  constructor(internal: Internal) {
    this.internal = internal;
  }

  async login(providers?: LoginProviderType[]): Promise<FaceLoginResponse | null> {
    assertArgument(
      providers,
      Array.isArray(providers) &&
        providers.length > 0 &&
        [...providers].every((provider) => Object.values(LoginProvider).includes(provider as any)),
      'providers',
      false
    );
    return this.internal.loginWithCredential(providers);
  }

  async directSocialLogin(provider: LoginProviderType): Promise<FaceLoginResponse | null> {
    assertArgument(provider, typeof provider === 'string', 'provider');

    return this.internal.directSocialLogin(provider);
  }

  async getIdToken(
    provider: LoginProviderType,
    accessToken: string
  ): Promise<FaceIdTokenResponse | null> {
    assertArgument(provider, typeof provider === 'string', 'provider');
    assertArgument(accessToken, typeof accessToken === 'string', 'accessToken');

    return this.internal.getIdToken(provider, accessToken);
  }

  async loginWithIdToken(
    loginWithIdTokenRequest: LoginWithIdTokenRequest
  ): Promise<FaceLoginResponse | null> {
    assertArgument(
      loginWithIdTokenRequest,
      loginWithIdTokenRequest.idToken && loginWithIdTokenRequest.sig,
      'loginWithIdTokenRequest'
    );

    return this.internal.loginWithIdToken(loginWithIdTokenRequest);
  }

  async loginWithAccessToken(
    loginWithAccessTokenRequest: LoginWithAccessTokenRequest
  ): Promise<FaceLoginResponse | null> {
    assertArgument(
      loginWithAccessTokenRequest,
      loginWithAccessTokenRequest.accessToken && loginWithAccessTokenRequest.sig,
      'loginWithIdTokenRequest'
    );

    return this.internal.loginWithAccessToken(loginWithAccessTokenRequest);
  }

  async logout(): Promise<void> {
    await this.internal.logout();
  }

  async getCurrentUser(): Promise<FaceLoginResponse | null> {
    return await this.internal.getCurrentUser();
  }

  async isLoggedIn(): Promise<boolean> {
    return await this.internal.isLoggedIn();
  }
}
