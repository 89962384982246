import { Internal } from '../internal';
import { SolanaProvider } from './SolanaProvider';

export class Solana {
  internal: Internal;
  constructor(internal: Internal) {
    this.internal = internal;
  }

  getProvider(): SolanaProvider {
    return new SolanaProvider(this.internal);
  }
}
