import {
  JsonRpcMethod,
  JsonRpcResponsePayload,
  Network,
  NetworkChainIdMap,
} from '@haechi-labs/face-types';

import { Internal } from './internal';

export interface RequestArguments {
  method: string;
  params?: any;

  [key: string]: any;
}

class ProviderTemplate {
  sendAsync = (r: any, c: (e: any, r: any) => void) => void 0;
}

export class Provider extends ProviderTemplate {
  private readonly internal: Internal;

  constructor(internal: Internal) {
    super();
    this.internal = internal;
  }

  async request(
    request: RequestArguments | RequestArguments[]
  ): Promise<JsonRpcResponsePayload['result']> {
    if (Array.isArray(request)) {
      return Promise.all(request.map((req) => this.internal.sendRpc(req)));
    }

    switch (request.method) {
      case JsonRpcMethod.wallet_switchEthereumChain:
        const network = NetworkChainIdMap[request.params[0].chainId] as Network;
        return this.internal.switchNetwork(network);
      case JsonRpcMethod.eth_estimateGas:
        return this.internal.estimateGas(request);
      case 'personal_listAccounts':
      case 'eth_accounts':
        return this.internal.getAddresses();
      default:
        return this.internal.sendRpc(request);
    }
  }
}
