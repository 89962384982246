import {
  Blockchain,
  BoraPortalConnectRequest,
  BoraPortalConnectStatusResponse,
  Env,
  FaceIdTokenResponse,
  FaceLoginResponse,
  HomeOptions,
  JsonRpcMethod,
  JsonRpcRequestPayload,
  JsonRpcResponsePayload,
  LoginProviderType,
  LoginWithAccessTokenRequest,
  LoginWithIdTokenRequest,
} from '@haechi-labs/face-types';
import { BigNumber, ethers } from 'ethers';

import { Face, Network } from './face';
import { Iframe } from './iframe';
import { getBlockchainFromNetwork, getDefaultEnv, getIframeUrl, isMainnet } from './utils';

const DEFAULT_ETH_GAS_PRICE = BigNumber.from(100000).toHexString();

type InternalParams = {
  face: Face;
  apiKey: string;
  network?: Network;
  env?: Env;
  iframeUrl?: string;
};

export class Internal {
  private readonly face: Face;
  private readonly network: Network;
  private readonly env: Env;
  public readonly iframe: Iframe;
  public readonly iframeUrl?: string;

  constructor({ apiKey, network, env, iframeUrl, face }: InternalParams) {
    this.network = network || Network.ETHEREUM;
    const blockchain = getBlockchainFromNetwork(this.network);
    // TODO: env is not configured yet, so need to change as env configured
    this.env = env ?? getDefaultEnv(network);
    this.iframe = new Iframe(apiKey, blockchain, this.env, getIframeUrl(this.env, iframeUrl));
    this.face = face;
    this.iframeUrl = iframeUrl;
  }

  async getAddresses(blockchain?: Blockchain): Promise<string[]> {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_accounts,
      params: [blockchain],
    });
    const response = await this.iframe.waitForResponse<null | string[]>(requestId);
    if (response) {
      return response;
    }
    return Promise.reject(new Error(`get address failed`));
  }

  // SDK에서 estimateGas를 호출하는 대부분의 경우는 트랜잭션을 보내는 상황에서 ethers가 호출하는 것
  // 이 상황에서는 0으로 리턴하고 iframe 안에서 덮어씌우는 게 유저 경험이 더 좋음
  async estimateGas(request: JsonRpcRequestPayload) {
    return 0;
  }

  async getBalance(address: string, contractAddress?: string): Promise<BigNumber> {
    if (contractAddress) {
      const callData = await this.encodeData(
        ['function balanceOf(address owner) view returns (uint256)'],
        'balanceOf',
        [address]
      );

      const result = await this.sendRpc({
        method: JsonRpcMethod.eth_call,
        params: [
          {
            to: contractAddress,
            data: callData,
          },
          'latest',
        ],
      });

      return BigNumber.from(result);
    }

    return BigNumber.from(
      await this.sendRpc({
        method: JsonRpcMethod.eth_getBalance,
        params: [address, 'latest'],
      })
    );
  }

  async ownerOf(contractAddress: string, tokenId: string): Promise<string> {
    const callData = await this.encodeData(
      ['function ownerOf(uint256 tokenId) view returns (address)'],
      'ownerOf',
      [tokenId]
    );
    const result = await this.sendRpc({
      method: JsonRpcMethod.eth_call,
      params: [
        {
          to: contractAddress,
          data: callData,
        },
        'latest',
      ],
    });

    return ('0x' + (result as string).substring(26)).toLowerCase();
  }

  async logout(): Promise<void> {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_logOut,
    });
    await this.iframe.waitForResponse<FaceLoginResponse | null>(requestId);
  }

  async getCurrentUser(): Promise<FaceLoginResponse | null> {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_currentUser,
    });
    return await this.iframe.waitForResponse<FaceLoginResponse>(requestId);
  }

  async isLoggedIn(): Promise<boolean> {
    await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_loggedIn,
    });
    const response = await this.iframe.waitForResponse<boolean>();
    if (response) {
      return response;
    }
    return false;
  }

  async ready(): Promise<void> {
    return this.iframe.ready();
  }

  async loginWithCredential(providers?: LoginProviderType[]): Promise<FaceLoginResponse | null> {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_logInSignUp,
      params: providers,
    });
    return await this.iframe.waitForResponse<FaceLoginResponse | null>(requestId);
  }

  async directSocialLogin(provider: LoginProviderType): Promise<FaceLoginResponse | null> {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_directSocialLogin,
      params: [provider],
    });
    return await this.iframe.waitForResponse<FaceLoginResponse | null>(requestId);
  }

  async getIdToken(
    provider: LoginProviderType,
    accessToken: string
  ): Promise<FaceIdTokenResponse | null> {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_getIdToken,
      params: [provider, accessToken],
    });
    return await this.iframe.waitForResponse<FaceIdTokenResponse | null>(requestId);
  }

  async loginWithIdToken(
    loginWithIdTokenRequest: LoginWithIdTokenRequest
  ): Promise<FaceLoginResponse | null> {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_loginWithIdToken,
      params: [loginWithIdTokenRequest],
    });
    return await this.iframe.waitForResponse<FaceLoginResponse | null>(requestId);
  }

  async loginWithAccessToken(
    loginWithAccessTokenRequest: LoginWithAccessTokenRequest
  ): Promise<FaceLoginResponse | null> {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_loginWithAccessToken,
      params: [loginWithAccessTokenRequest],
    });
    return await this.iframe.waitForResponse<FaceLoginResponse | null>(requestId);
  }

  async openWalletConnect(name: string, url: string): Promise<void> {
    this.iframe.throwExceptionUnsupportedBlockchain([
      Blockchain.APTOS,
      Blockchain.NEAR,
      Blockchain.SOLANA,
      Blockchain.PSM,
    ]);

    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_openWalletConnect,
      params: [name, url],
    });
    return await this.iframe.waitForResponse<void>(requestId);
  }

  async openHome(options?: HomeOptions): Promise<void> {
    this.iframe.throwExceptionUnsupportedBlockchain([
      Blockchain.APTOS,
      Blockchain.SOLANA,
      Blockchain.NEAR,
    ]);

    const isCurrentNetworkMainnet = isMainnet(this.network);
    if (
      options?.networks &&
      options.networks.some((network) => isMainnet(network) !== isCurrentNetworkMainnet)
    ) {
      const message = `You initialized the Face SDK with ${
        isCurrentNetworkMainnet ? 'Mainnet' : 'Testnet'
      }. Please open the wallet home in the same environment as the initialized network.`;
      throw new Error(message);
    }

    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_openHome,
      params: [options],
    });
    return this.iframe.waitForResponse(requestId);
  }

  async sendRpc<T>(
    rpcPayload: JsonRpcRequestPayload
  ): Promise<JsonRpcResponsePayload<T>['result']> {
    const requestId = await this.iframe.sendChildMessage(rpcPayload);
    return await this.iframe.waitForResponse<JsonRpcResponsePayload<T>['result']>(requestId);
  }

  async decodeData(serializedTx: string, abi: string[]) {
    const ethersInterface = new ethers.utils.Interface(abi);
    const { name, args } = ethersInterface.parseTransaction({ data: serializedTx });

    return { name, args };
  }

  async encodeData(abi: string[], functionFragment: string, args?: any[]) {
    const ethersInterface = new ethers.utils.Interface(abi);
    return ethersInterface.encodeFunctionData(functionFragment, args);
  }

  async switchNetwork(network: Network) {
    const blockchain = getBlockchainFromNetwork(network);
    const request = {
      method: JsonRpcMethod.face_switchNetwork,
      params: [{ blockchain }],
    };
    const res = await this.sendRpc(request);

    this.face.setNetwork(network);

    this.iframe.setBlockchain(blockchain);

    return res;
  }

  async boraIsConnected(bappUsn: string): Promise<BoraPortalConnectStatusResponse | null> {
    this.iframe.throwExceptionUnsupportedBlockchain([
      Blockchain.APTOS,
      Blockchain.NEAR,
      Blockchain.KLAYTN,
      Blockchain.BNB_SMART_CHAIN,
      Blockchain.ETHEREUM,
      Blockchain.MEVERSE,
      Blockchain.SOLANA,
      Blockchain.POLYGON,
      Blockchain.PSM,
    ]);

    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.bora_isConnected,
      params: [bappUsn],
    });
    return await this.iframe.waitForResponse<BoraPortalConnectStatusResponse | null>(requestId);
  }

  async boraConnect(
    request: BoraPortalConnectRequest
  ): Promise<BoraPortalConnectStatusResponse | null> {
    this.iframe.throwExceptionUnsupportedBlockchain([
      Blockchain.APTOS,
      Blockchain.NEAR,
      Blockchain.KLAYTN,
      Blockchain.BNB_SMART_CHAIN,
      Blockchain.ETHEREUM,
      Blockchain.MEVERSE,
      Blockchain.SOLANA,
      Blockchain.POLYGON,
      Blockchain.PSM,
    ]);

    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.bora_connect,
      params: [request],
    });
    return await this.iframe.waitForResponse<BoraPortalConnectStatusResponse | null>(requestId);
  }
}
