import { assertArgument } from '@haechi-labs/face-types';

import { Internal } from './internal';

export class WalletConnect {
  private readonly internal: Internal;

  constructor(internal: Internal) {
    this.internal = internal;
  }

  async connectOpensea(collectionName?: string): Promise<void> {
    assertArgument(collectionName, typeof collectionName === 'string', 'collectionName', false);

    await this.connect(
      'OpenSea',
      collectionName ? 'https://opensea.io/collection/' + collectionName : 'https://opensea.io'
    );
  }

  // Todo : 일반적인 dApp 의 가이드 개발이 완료되기 전까지는 private 로 유지한다.
  private async connect(name: string, url: string) {
    assertArgument(name, typeof name === 'string', 'name');
    assertArgument(url, typeof url === 'string', 'url');

    await this.internal.openWalletConnect(name, url);
  }
}
