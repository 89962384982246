import {
  assertArgument,
  BoraPortalConnectRequest,
  BoraPortalConnectStatusResponse,
} from '@haechi-labs/face-types';

import { Internal } from '../internal';

export class Bora {
  internal: Internal;

  constructor(internal: Internal) {
    this.internal = internal;
  }

  async connect(
    request: BoraPortalConnectRequest
  ): Promise<BoraPortalConnectStatusResponse | null> {
    assertArgument(request, request.bappUsn && request.signature, 'request');

    return await this.internal.boraConnect(request);
  }

  async isConnected(bappUsn: string): Promise<BoraPortalConnectStatusResponse | null> {
    assertArgument(bappUsn, typeof bappUsn === 'string', 'bappUsn');

    return await this.internal.boraIsConnected(bappUsn);
  }
}
